<mat-tab-group
  *transloco="let t; read: 'profile'"
  class="mat-tab-group"
  mat-stretch-tabs="false"
  mat-align-tabs="center"
>
  <mat-tab label="{{ t('userInfo') }}">
    <form
      *transloco="let t; read: 'userInfo'"
      class="user-info-form"
      [formGroup]="userInfoForm"
      (ngSubmit)="onSubmitUserInfo($event)"
    >
      <mat-card class="user-info-card">
        <mat-card-header class="flex-center">
          <mat-card-title>
            <h1 class="text-2xl sm:text-3xl">{{ t('title') }}</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="full-width profile-avatar-container">
              <div class="profile-img-container">
                <img
                  class="profile-img"
                  [src]="(avatarInfo$ | async)?.avatarUrl"
                  [alt]="
                    (avatarInfo$ | async)?.avatarUrl
                      ? 'picture of ' +
                        userInfoForm.controls['firstName'].value +
                        ' ' +
                        userInfoForm.controls['lastName'].value
                      : 'profile avatar placeholder'
                  "
                />
                <button
                  mat-mini-fab
                  color="accent"
                  (click)="imageInput.click()"
                >
                  <mat-icon> edit </mat-icon>
                </button>
              </div>

              <div style="display: flex; gap: 1rem">
                <button
                  [disabled]="isSubmittingUpload$ | async"
                  *ngIf="showUploadButton$ | async"
                  type="button"
                  mat-raised-button
                  color="accent"
                  (click)="uploadImage()"
                >
                  {{ t('upload') }}
                </button>

                <div *ngIf="avatarInfo$ | async as avatarInfo">
                  <button
                    [disabled]="isSubmittingDelete$ | async"
                    *ngIf="avatarInfo.hasAvatar"
                    type="button"
                    mat-raised-button
                    color="warn"
                    (click)="deleteAvatar()"
                  >
                    Delete Avatar -{{ isSubmittingDelete$ | async }}
                  </button>
                </div>
              </div>

              <input
                hidden
                #imageInput
                (change)="selectFile(imageInput)"
                type="file"
                accept=".jpg, .jpeg, .png"
              />
            </div>
          </div>
          <div style="color: red; padding-left: 30px; padding-bottom: 20px">
            {{ backendErrorLoading$ | async }}
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('firstNameLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('firstNameLabel') }}"
                  formControlName="firstName"
                />
                <mat-error
                  *ngIf="userInfoForm.controls['firstName'].hasError('pattern')"
                >
                  {{ t('firstNameLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lastNameLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('lastNameLabel') }}"
                  formControlName="lastName"
                />
                <mat-error
                  *ngIf="userInfoForm.controls['lastName'].hasError('pattern')"
                >
                  {{ t('lastNameLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div
              class="col phone-field"
              matAutocompleteOrigin
              #origin="matAutocompleteOrigin"
            >
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('phoneLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="+55119988776655"
                  formControlName="phone"
                />
                <mat-error
                  *ngIf="userInfoForm.controls['phone'].hasError('maxlength')"
                >
                  {{ t('phoneLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions
          class="flex-center full-width"
          style="padding-bottom: 1.5rem"
        >
          <div class="row flex-center">
            <div class="col">
              <button
                [disabled]="isSubmittingSave$ | async"
                type="submit"
                mat-raised-button
                color="accent"
              >
                {{ t('save') }}
              </button>
            </div>
          </div>
        </mat-card-actions>
        <div class="row flex-center">
          <!-- <p>{{ t('title') }}</p> -->
        </div>

        <mat-accordion>
          <mat-expansion-panel
            *transloco="let t; read: 'userAddress'"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p *ngIf="hasAddress$ | async">{{ t('showAddress') }}</p>
                <p *ngIf="!(hasAddress$ | async)">{{ t('addAddress') }}</p>
              </mat-panel-title>
              <mat-panel-description>
                <p *ngIf="hasAddress$ | async">
                  {{ t('showAddressDescription') }}
                </p>
                <p *ngIf="!(hasAddress$ | async)">
                  {{ t('addAddressDescription') }}
                </p>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <form
              [formGroup]="userAddressForm"
              (ngSubmit)="onSubmitUserAddress($event)"
            >
              <div class="row" style="margin-top: 1.5rem">
                <div class="col" style="margin-inline: 0">
                  <!-- <button
                mat-mini-fab
                color="accent"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>add</mat-icon>
              </button>
              <button
                mat-mini-fab
                color="accent"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>remove</mat-icon>
              </button> -->

                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="full-width"
                      >
                        <mat-label>{{ t('streetLabel') }}</mat-label>
                        <input
                          matInput
                          type="text"
                          placeholder="{{ t('streetLabel') }}"
                          formControlName="street"
                        />
                        <mat-error
                          *ngIf="
                            streetField().hasError('pattern') ||
                            streetField().hasError('required')
                          "
                        >
                          {{ t('streetLabel') + ' ' + t('errorIsInvalid') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="full-width"
                      >
                        <mat-label>{{ t('houseNumberLabel') }}</mat-label>
                        <input
                          matInput
                          type="text"
                          placeholder="{{ t('houseNumberLabel') }}"
                          formControlName="houseNo"
                        />
                        <mat-error
                          *ngIf="
                            houseNumberField().hasError('pattern') ||
                            houseNumberField().hasError('required')
                          "
                        >
                          {{
                            t('houseNumberLabel') + ' ' + t('errorIsInvalid')
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="full-width"
                      >
                        <mat-label>{{ t('cityLabel') }}</mat-label>
                        <input
                          matInput
                          type="text"
                          placeholder="{{ t('cityLabel') }}"
                          formControlName="city"
                        />
                        <mat-error
                          *ngIf="
                            cityField().hasError('pattern') ||
                            cityField().hasError('required')
                          "
                        >
                          {{ t('cityLabel') + ' ' + t('errorIsInvalid') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="full-width"
                      >
                        <mat-label>{{ t('stateLabel') }}</mat-label>
                        <input
                          matInput
                          type="text"
                          placeholder="{{ t('stateLabel') }}"
                          formControlName="state"
                        />
                        <mat-error
                          *ngIf="
                            stateField().hasError('pattern') ||
                            stateField().hasError('required')
                          "
                        >
                          {{ t('stateLabel') + ' ' + t('errorIsInvalid') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="full-width"
                      >
                        <mat-label>{{ t('countryLabel') }}</mat-label>
                        <input
                          type="text"
                          placeholder="{{ t('countryLabel') }}"
                          matInput
                          formControlName="country"
                          [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                          autoActiveFirstOption
                          #auto="matAutocomplete"
                        >
                          <mat-option
                            *ngFor="let country of filteredCountries | async"
                            [value]="country.name"
                          >
                            {{ country.name }}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error
                          *ngIf="
                            countryField().hasError('countryvalidator') ||
                            countryField().hasError('required')
                          "
                        >
                          {{ t('countryLabel') + ' ' + t('errorIsInvalid') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="full-width"
                      >
                        <mat-label>{{ t('postalCodeLabel') }}</mat-label>
                        <input
                          matInput
                          #postalCode
                          placeholder="{{ t('postalCodeLabel') }}"
                          type="text"
                          formControlName="postCode"
                        />
                        <mat-error
                          *ngIf="
                            postalCodeField().hasError('pattern') ||
                            postalCodeField().hasError('required')
                          "
                        >
                          {{ t('postalCodeLabel') + ' ' + t('errorIsInvalid') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <mat-action-row class="flex-center">
                <button
                  [disabled]="isSubmittingAddressSave$ | async"
                  type="submit"
                  mat-raised-button
                  color="accent"
                >
                  {{ t('save') }}
                </button>
              </mat-action-row>
            </form>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="{{ t('security') }}">Content 2</mat-tab>
  <mat-tab label="{{ t('masjids') }}">Content 3</mat-tab>
</mat-tab-group>
