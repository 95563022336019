import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, exhaustMap, pipe, tap } from 'rxjs'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AuthService } from '../../../shared/services/auth.service'
import { registerSuccessAction } from '../actions/register.actions'

export interface RegisterState {
    isSubmitting: boolean
    backendError: string | null
    userAlreadyExistsErr: string | null
}

export const RETURN_URL_QUERY_PARAM = 'return_url'

export const initialState: RegisterState = {
    isSubmitting: false,
    backendError: null,
    userAlreadyExistsErr: null,
}

export interface RegisterRequestInterface {
    email: string
    password: string
}

@Injectable()
export class RegisterStore extends ComponentStore<RegisterState> {
    constructor(
        private authService: AuthService,
        private store: Store,
        private _notification: NotificationService
    ) {
        super(initialState)
    }
    readonly isSubmitting$: Observable<boolean> = this.select(
        (state) => state.isSubmitting
    )

    readonly backendError$: Observable<string | null> = this.select(
        (state) => state.backendError
    )
    readonly userAlreadyExistsErr$: Observable<string | null> = this.select(
        (state) => state.userAlreadyExistsErr
    )
    private readonly setIsSubmitting = this.updater(
        (state, isSubmitting: boolean) => ({
            isSubmitting: isSubmitting,
            backendError: state.backendError,
            userAlreadyExistsErr: state.userAlreadyExistsErr,
        })
    )

    private readonly setBackendError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            userAlreadyExistsErr: null,
            backendError: error,
        })
    )
    private readonly setUserAlreadyExistsErr = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            userAlreadyExistsErr: error,
            backendError: null,
        })
    )

    readonly doRegister = this.effect<RegisterRequestInterface>(
        pipe(
            tap(() => {
                this.setIsSubmitting(true)
                this._notification.loading()
            }),
            // 👇 Handle race condition with the proper choice of the flattening operator.
            exhaustMap((request: RegisterRequestInterface) =>
                this.authService.register(request).pipe(
                    tapResponse(
                        (currentUser) => {
                            this.store.dispatch(
                                registerSuccessAction({
                                    currentUser,
                                })
                            )
                            console.log('register currentUser:', currentUser)
                            this.setIsSubmitting(false)
                            this._notification.removeLoading()
                            this._notification.notifySuccess(
                                'User has been registered successfully'
                            )
                        },
                        (err: Error) => {
                            this._notification.removeLoading()
                            console.log('register error:', err)

                            if (err.message.includes('already exists')) {
                                this.setUserAlreadyExistsErr(err.message)
                            } else {
                                this.setBackendError(err.message)
                                this._notification.notifyFailure(err.message)
                            }
                        }
                    )
                )
            )
        )
    )
}
