import { Component, Inject, OnInit } from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms'
import { provideComponentStore } from '@ngrx/component-store'
import { Store, select } from '@ngrx/store'
import { Observable, map, startWith, take } from 'rxjs'
import { Country } from 'src/app/shared/interfaces/country.interface'
import {
    APPWRITE_SDK,
    AppwriteSdk,
} from 'src/app/shared/services/appwrite.provider'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { AuthService } from '../../../shared/services/auth.service'
import { AuthStateInterface } from '../../interfaces/authState.interface'
import { authFeatureSelector } from '../../store/selectors'
import {
    AvatarInfo,
    DefaultAvatarSVGPath,
    ProfileStore,
    SaveUserAddressRequest,
} from '../../store/states/profile.state'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
// TODO: link for autoFocuse
// https://medium.com/@wildhammer/angular-material-phone-input-2283c7bc3372
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [provideComponentStore(ProfileStore)],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatTabsModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatLabel,
        MatAutocompleteModule,
        MatExpansionModule,
        TranslocoModule,
        MatInputModule
    ],
})
export class ProfileComponent implements OnInit {
    authUser$: Observable<AuthStateInterface | null>
    avatarFile?: File
    avatarExists: boolean = false
    isSubmittingUpload$: Observable<boolean>
    showUploadButton$: Observable<boolean>
    isSubmittingSave$: Observable<boolean>
    isSubmittingAddressSave$: Observable<boolean>
    isSubmittingDelete$: Observable<boolean>
    backendErrorSave$: Observable<string | null>
    backendErrorUpload$: Observable<string | null>
    backendErrorLoading$: Observable<string | null>
    formValues$: Observable<{ [key: string]: any }>
    avatarInfo$: Observable<AvatarInfo>
    hasAddress$: Observable<boolean>
    countries: Country[] = []
    filteredCountries?: Observable<Country[]>
    panelOpenState = false

    userInfoForm: FormGroup
    userAddressForm: FormGroup
    hide = true
    constructor(
        private _fb: FormBuilder,
        private _authService: AuthService,
        private _store: Store,
        private componentStore: ProfileStore,
        private _fileService: FileStorageService,

        @Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk
    ) {
        this.userInfoForm = this._fb.group({
            firstName: ['', [Validators.maxLength(50)]],
            lastName: ['', [Validators.maxLength(50)]],
            phone: ['', [Validators.maxLength(30)]],
        })
        this.userAddressForm = this._fb.group(
            {
                street: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            '^([0-9]+[ ]?)?([a-zA-Z]+[ ]?){0,}?'
                        ),
                    ],
                ],
                houseNo: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern('^([0-9]+)[ ]?([a-zA-Z]{1,3})?'),
                    ],
                ],
                country: [null, [Validators.required, this.countryValidator()]],
                city: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                        ),
                    ],
                ],
                state: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                        ),
                    ],
                ],
                postCode: ['', [Validators.required, Validators.maxLength(10)]],
            },
            { validators: this.fullAddressValidator }
        )

        this.authUser$ = this._store.pipe(select(authFeatureSelector))
        this.isSubmittingSave$ = componentStore.isSubmittingSave$
        this.isSubmittingAddressSave$ = componentStore.isSubmittingAddressSave$
        this.isSubmittingDelete$ = componentStore.isSubmittingDelete$
        this.isSubmittingUpload$ = componentStore.isSubmittingUpload$
        this.showUploadButton$ = componentStore.showUploadButton$
        this.backendErrorSave$ = componentStore.backendErrorSave$
        this.backendErrorUpload$ = componentStore.backendErrorUpload$
        this.backendErrorLoading$ = componentStore.backendErrorLoading$
        this.formValues$ = componentStore.formValues$
        this.avatarInfo$ = componentStore.avatarInfo$
        this.hasAddress$ = componentStore.hasAddress$
    }

    ngOnInit(): void {
        this._authService
            .getCountries()
            .pipe(take(1))
            .subscribe((countries) => {
                this.countries = countries
            })
        this.filteredCountries = this.countryField().valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
        )
        this.authUser$.pipe(take(1)).subscribe((auth) => {
            console.log('onInit - new currentUser value:', auth)

            if (auth?.userPreferences?.avatar_id !== undefined) {
                // this.componentStore.loadAvatar(auth?.userPrefs?.avatar_id!)

                const avatarUrl = this._fileService.getAvatarPreviewUrl(
                    auth?.userPreferences?.avatar_id!
                )
                console.log('real avatar: ', avatarUrl)
                this.componentStore.setAvatarInfo({
                    avatarUrl,
                    hasAvatar: true,
                })
            } else {
                this.componentStore.setAvatarInfo({
                    avatarUrl: DefaultAvatarSVGPath,
                    hasAvatar: false,
                })
            }
            this.componentStore.loadUserAddress(auth!)
            if (auth?.userPreferences) {
                console.log('got userPrefs:', auth?.userPreferences)
                this.userInfoForm.patchValue({
                    phone: auth.userPreferences.phone,
                })
            }
            this.userInfoForm.patchValue({
                firstName: auth?.currentUser?.firstName,
                lastName: auth?.currentUser?.lastName,
            })
            this.formValues$
                .pipe(take(2))
                .subscribe((values: { [key: string]: any }) => {
                    if (Object.keys(values).length !== 0) {
                        console.log('form Values:', values)
                        this.userAddressForm.setValue(values)
                    }
                })
        })
    }

    streetField() {
        return this.userAddressForm.controls['street']
    }
    houseNumberField() {
        return this.userAddressForm.controls['houseNo']
    }
    countryField() {
        return this.userAddressForm.controls['country']
    }
    stateField() {
        return this.userAddressForm.controls['state']
    }
    cityField() {
        return this.userAddressForm.controls['city']
    }
    postalCodeField() {
        return this.userAddressForm.controls['postCode']
    }

    onSubmitUserInfo(e: any): void {
        e.preventDefault()

        const data = {
            ...this.userInfoForm.value,
        }
        this.authUser$.pipe(take(1)).subscribe((auth) => {
            console.log(
                'onSubmitUserInfo - new currentUser value:',
                auth?.currentUser
            )
            if (auth === null) return
            const user = auth.currentUser
            if (user === null) return
            const firstName = data.firstName.trim()
            const lastName = data.lastName.trim()
            let needUpdate = false
            if (firstName !== user.firstName) {
                needUpdate = true
            }
            if (lastName !== user.lastName) {
                needUpdate = true
            }
            if (needUpdate) {
                console.log('calling updateName:', firstName, lastName)
                this.componentStore.updateName({ firstName, lastName })
            }
            if (data.phone) {
                // this to be fixed later with country code
                let prefs = {
                    ...auth.userPreferences,
                }
                prefs.phone = data.phone
                console.log('updating user phone,prefs:', prefs)
                this.componentStore.updatePhone(prefs)
            }
        })
    }

    onSubmitUserAddress(e: any): void {
        e.preventDefault()

        // TODO: refactor to check:
        // 1. form is valid
        console.log('address values:', this.userAddressForm.value)
        this.validateFormValues(this.userAddressForm.value)
        if (this.userAddressForm.invalid) {
            console.log(
                'form invalid',
                this.countryField().hasError('countryvalidator'),
                this.userAddressForm.value
            )

            return
        }
        const data = {
            ...this.userAddressForm.value,
        }

        this.authUser$.pipe(take(1)).subscribe((auth) => {
            console.log('user logged in ', auth?.currentUser)

            const req: SaveUserAddressRequest = {
                auth: auth!,
                address: data,
            }
            if (req.auth.userPreferences?.has_address === true) {
                this.componentStore.updateUserAddress(req)
            } else {
                this.componentStore.createUserAddress(req)
            }
        })
    }

    fullAddressValidator(control: AbstractControl): ValidationErrors | null {
        if (
            control.get('street')?.value !== '' ||
            control.get('houseNo')?.value !== '' ||
            control.get('country')?.value !== '' ||
            control.get('city')?.value !== '' ||
            control.get('state')?.value !== '' ||
            control.get('postCode')?.value !== ''
        ) {
            console.log('one field has value')
            // if (this.userAddressForm.hasError('fullAddressValidator')) {
            //     this.signupForm.controls['confirmPassword'].setErrors({
            //         passwordMatch: true,
            //     })
            // }
            // if (this.userAddressForm.invalid) {
            // }
        } else {
            console.log('the form is empty')
        }
        return null
        // return control.get('password')?.value ===
        //     control.get('confirmPassword')?.value
        //     ? null
        //     : { passwordMatch: true }
    }
    validateFormValues(values: any) {
        var filled = false
        if (
            values.street !== '' ||
            values.houseNo !== '' ||
            values.country !== '' ||
            values.city !== '' ||
            values.state !== '' ||
            values.postCode !== ''
        ) {
            filled = true
        }
        if (filled) {
        }
    }

    private _filter(value: string): Country[] {
        if (value === null) {
            value = ''
        }
        const filterValue = value.toLowerCase()

        return this.countries.filter((country) =>
            // This is material ui option
            // country.name.toLowerCase().includes(filterValue)

            // I think this is a better way
            country.name.toLowerCase().startsWith(filterValue)
        )
    }

    countryValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === '') {
                console.log('country-validator: value is null', control.value)
                return null
            }
            const isValid = this.countries
                .map((country) => country.name.toLocaleLowerCase())
                .some(
                    (countryName) => countryName === control.value.toLowerCase()
                )
            console.log('country-validator: isValid: ', isValid)
            console.log('country-validator: value: ', control.value)

            return isValid ? null : { countryvalidator: true }
        }
    }

    selectFile(imageInput: any) {
        const file: File = imageInput.files[0]
        const reader = new FileReader()
        if (file) {
            reader.onload = (event: any) => {
                this.componentStore.setAvatarInfo({
                    avatarUrl: event.target.result,
                    hasAvatar: false,
                })
                this.avatarFile = file
                this.componentStore.setShowUploadButton(true)
            }
            reader.readAsDataURL(file)
        }
    }

    deleteAvatar() {
        this.authUser$.pipe(take(1)).subscribe((auth) => {
            // this.componentStore.setIsSubmittingDelete(true)
            this.componentStore.deleteAvatar(auth?.userPreferences!)
            // this.componentStore.setIsSubmittingDelete(false)
        })
    }

    uploadImage() {
        // add uploading image logic
        // call getPreview to get the url
        // set the avatarUrl with the href of getPreview
        // update the document with the avatar url
        console.log('upload called')
        this.authUser$.pipe(take(1)).subscribe((auth) => {
            if (this.avatarFile) {
                const prefs = {
                    // make a copy of the userPrefs object
                    ...auth?.userPreferences,
                }
                const payload = {
                    userPrefs: prefs,
                    userID: auth?.currentUser?.userID!,
                    file: this.avatarFile,
                }
                console.log('calling componenet store, upload image.')
                if (prefs.avatar_id === undefined) {
                    this.componentStore.uploadNewImage(payload)
                } else {
                    this.componentStore.updateImage(payload)
                }
            }
        })
    }
}
