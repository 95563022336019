<div class="login-page flex-center" *transloco="let t; read: 'login'">
  <div class="col">
    <div class="row">
      <mat-card class="login-card">
        <form
          class="login-form"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit($event)"
        >
          <mat-card-header class="flex-center">
            <mat-card-title>
              <h1 class="text-2xl sm:text-3xl">{{ t('title') }}</h1>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill" class="full-width">
                  <mat-label>{{ t('emailLable') }}</mat-label>
                  <input
                    matInput
                    type="email"
                    placeholder="example@mail.com"
                    formControlName="email"
                    required
                    autocomplete="email"
                    aria-required="true"
                  />
                  <mat-error *ngIf="email?.invalid">{{
                    t('errorEmailInvalid')
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill" class="full-width">
                  <mat-label>{{ t('passwordLabel') }}</mat-label>
                  <input
                    matInput
                    [type]="hide ? 'password' : 'text'"
                    placeholder="{{ t('passwordPlaceHolder') }}"
                    formControlName="password"
                    required
                    autocomplete="current-password"
                    aria-required="true"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      hide ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                  </button>
                  <mat-error *ngIf="password?.hasError('required')">{{
                    t('errorPasswordIsRequired')
                  }}</mat-error>
                  <mat-error *ngIf="password?.hasError('minlength')">{{
                    t('errorMinValue')
                  }}</mat-error>
                  <mat-error *ngIf="password?.hasError('maxlength')">{{
                    t('errorMaxValue')
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div style="color: red">
              {{ backendError$ | async }}
            </div>
            <div style="color: red" *ngIf="invalidPasswordError$ | async">
              {{ t('invalidPasswordError') }}
            </div>
          </mat-card-content>
          <mat-card-actions class="flex-center full-width pi-16px">
            <div class="row flex-center">
              <div class="row">
                <p>
                  <a [routerLink]="['/auth/reset']" class="underline">
                    {{ t('forgotPasswordLink') }}
                  </a>
                </p>
              </div>
              <div class="row">
                <button
                  type="submit"
                  [disabled]="isSubmitting$ | async"
                  mat-raised-button
                  color="accent"
                >
                  {{ t('submitLabel') }}
                </button>
              </div>
            </div>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>

    <div class="row">
      <mat-card class="login-card">
        <mat-card-content>
          <div class="row">
            <p class="text-sm text-gray-500 signup-text">
              {{ t('alreadyLinkLabel') }}
              <a [routerLink]="['/auth/register']" class="underline">{{
                t('registerLink')
              }}</a>
            </p>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
