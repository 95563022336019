import { Routes } from "@angular/router";
import { AuthenticatedGuard } from "../shared/guards/authenticated.guard";
import { LoginComponent } from "./components/login/login.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { RecoverComponent } from "./components/recover/recover.component";
import { RegisterComponent } from "./components/register/register.component";
import { ResetComponent } from "./components/reset/reset.component";

export const authRoutes: Routes = [
    {
        // if '/auth' only called redirects to /home
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: '',
        children: [
            { path: 'register', component: RegisterComponent },
            { path: 'login', component: LoginComponent },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AuthenticatedGuard],
            },
            { path: 'recover', component: RecoverComponent },
            { path: 'reset', component: ResetComponent },
        ],
    },
]