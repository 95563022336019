import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, concatMap, iif, map, of, pipe, switchMap } from 'rxjs'
import { UserPreferenceInterface } from 'src/app/shared/interfaces/currentUser.interface'
import { UserAddress } from 'src/app/shared/interfaces/userAddress.interface'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { UserAddressDocumentService } from 'src/app/shared/services/user-address.service'
import { AuthService } from '../../../shared/services/auth.service'
import { AuthStateInterface } from '../../interfaces/authState.interface'
import {
    refreshCurrentUserAction,
    refreshUserPreferencesAction,
} from '../actions/refresh.actions'

export const DefaultAvatarSVGPath = 'assets/images/profile-avatar.svg'
export interface ProfileState {
    showUploadButton: boolean
    isSubmittingUpload: boolean
    isSubmittingSave: boolean
    isSubmittingAddressSave: boolean
    isSubmittingDelete: boolean
    backendErrorUpload: string | null
    backendErrorSave: string | null
    backendErrorLoading: string | null
    formValues: { [key: string]: any }
    avatarInfo: AvatarInfo
    hasAddress: boolean
}

export interface UploadImagePayload {
    userPrefs: UserPreferenceInterface
    userID: string
    file: File
}
export interface AvatarInfo {
    avatarUrl: string
    hasAvatar: boolean
}
export interface UserNameRequest {
    firstName: string
    lastName: string
}

export interface SaveUserAddressRequest {
    auth: AuthStateInterface
    address: UserAddress
}

export const initialState: ProfileState = {
    showUploadButton: false,
    isSubmittingUpload: false,
    isSubmittingSave: false,
    isSubmittingAddressSave: false,
    isSubmittingDelete: false,
    backendErrorSave: null,
    backendErrorUpload: null,
    backendErrorLoading: null,
    formValues: {},
    avatarInfo: {
        avatarUrl: DefaultAvatarSVGPath,
        hasAvatar: false,
    },
    hasAddress: false,
}

@Injectable()
export class ProfileStore extends ComponentStore<ProfileState> {
    constructor(
        private auth: AuthService,
        private fileService: FileStorageService,
        private store: Store,
        private userAddressService: UserAddressDocumentService
    ) {
        super(initialState)
    }
    readonly isSubmittingUpload$: Observable<boolean> = this.select(
        (state) => state.isSubmittingUpload
    )
    readonly showUploadButton$: Observable<boolean> = this.select(
        (state) => state.showUploadButton
    )
    readonly isSubmittingSave$: Observable<boolean> = this.select(
        (state) => state.isSubmittingSave
    )
    readonly isSubmittingAddressSave$: Observable<boolean> = this.select(
        (state) => state.isSubmittingAddressSave
    )
    readonly isSubmittingDelete$: Observable<boolean> = this.select(
        (state) => state.isSubmittingDelete
    )
    readonly hasAddress$: Observable<boolean> = this.select(
        (state) => state.hasAddress
    )
    readonly backendErrorUpload$: Observable<string | null> = this.select(
        (state) => state.backendErrorUpload
    )
    readonly backendErrorSave$: Observable<string | null> = this.select(
        (state) => state.backendErrorSave
    )
    readonly backendErrorLoading$: Observable<string | null> = this.select(
        (state) => state.backendErrorLoading
    )
    readonly formValues$: Observable<{ [key: string]: any }> = this.select(
        (state) => state.formValues
    )
    readonly avatarInfo$: Observable<AvatarInfo> = this.select(
        (state) => state.avatarInfo
    )

    readonly setIsSubmittingDelete = this.updater(
        (state, isSubmitting: boolean) => ({
            ...state,
            isSubmittingDelete: isSubmitting,
        })
    )
    readonly setShowUploadButton = this.updater((state, show: boolean) => ({
        ...state,
        showUploadButton: show,
    }))
    readonly setFormValues = this.updater(
        (state, values: { [key: string]: any }) => ({
            ...state,
            formValues: values,
            showUploadButton: false,
            isSubmittingSave: false,
            isSubmittingDelete: false,
            isSubmittingUpload: false,
            backendErrorSave: null,
            backendErrorUpload: null,
            backendErrorLoading: null,
        })
    )

    private readonly setBackendErrorLoading = this.updater(
        (state, error: string) => ({
            ...state,
            backendErrorLoading: error,
            formValues: state.formValues,
            isSubmittingSave: false,
            isSubmittingDelete: false,
            isSubmittingUpload: false,
            backendErrorSave: null,
            backendErrorUpload: null,
        })
    )

    private readonly setIsSubmittingSave = this.updater(
        (state, isSubmitting: boolean) => ({
            ...state,
            isSubmittingSave: isSubmitting,
            backendErrorLoading: null,
        })
    )
    private readonly setIsSubmittingAddressSave = this.updater(
        (state, isSubmitting: boolean) => ({
            ...state,
            isSubmittingAddressSave: isSubmitting,
            backendErrorLoading: null,
        })
    )

    private readonly setIsSubmittingUpload = this.updater(
        (state, isSubmitting: boolean) => ({
            ...state,
            isSubmittingUpload: isSubmitting,
            backendErrorLoading: null,
        })
    )
    private readonly setHasAddress = this.updater(
        (state, hasAddress: boolean) => ({
            ...state,
            hasAddress: hasAddress,
            backendErrorLoading: null,
        })
    )
    private readonly setBackendErrorSave = this.updater(
        (state, error: string | null) => ({
            ...state,
            backendErrorSave: error,
            isSubmittingSave: false,
            isSubmittingUpload: false,
            backendErrorUpload: null,
            backendErrorLoading: null,
        })
    )

    private readonly setBackendErrorUpload = this.updater(
        (state, error: string | null) => ({
            ...state,
            backendErrorUpload: error,
            showUploadButton: false,
            isSubmittingSave: false,
            isSubmittingDelete: false,
            isSubmittingUpload: false,
            backendErrorSave: null,
            backendErrorLoading: null,
        })
    )

    readonly setAvatarInfo = this.updater((state, info: AvatarInfo) => ({
        ...state,
        avatarInfo: info,
    }))

    readonly loadUserAddress = this.effect<AuthStateInterface>(
        pipe(
            switchMap((auth) => {
                // 👇 Handle race condition with the proper choice of the flattening operator.
                const EmptyUserAddressDoc = {
                    house_number: '',
                    street: '',
                    city: '',
                    state: '',
                    country: '',
                    post_code: '',
                }
                this.setHasAddress(auth.userPreferences?.has_address === true)
                // iff checks if has_address calls findUserAddressDocuemnt  to return the address,
                //  otherwise return empty address
                return iif(
                    () => auth.userPreferences?.has_address === true,
                    this.userAddressService
                        .findUserAddressDocument(auth.currentUser?.userID!)
                        .pipe(map((list) => list.documents[0])),
                    of(EmptyUserAddressDoc)
                )
            }),
            tapResponse(
                (document) => {
                    // if we have document from the store means the user has address already
                    const values =
                        this.userAddressResponseToFormValues(document)
                    console.log('setting form values:', values)
                    this.setFormValues(values)
                },
                (error: Error) => {
                    this.setBackendErrorLoading(error.message)
                }
            )
        )
    )

    readonly updateUserAddress = this.effect<SaveUserAddressRequest>(
        pipe(
            concatMap((req) => {
                // 👇 Handle race condition with the proper choice of the flattening operator.
                console.log(
                    'condition: ',
                    req.auth.userPreferences?.has_address === true
                )
                this.setIsSubmittingAddressSave(true)
                return this.userAddressService
                    .updateUserAddressDocument(
                        req.auth.currentUser?.userID!,
                        req.address
                    )
                    .pipe(
                        switchMap(() => {
                            req.auth.userPreferences
                            const prefs = {
                                ...req.auth.userPreferences,
                                has_address: true,
                            }
                            return this.auth.updateUserPreferences(prefs)
                        })
                    )
            }),
            tapResponse(
                (userPrefs) => {
                    this.store.dispatch(
                        refreshUserPreferencesAction({
                            userPreferences: userPrefs,
                        })
                    )
                    this.setIsSubmittingAddressSave(false)
                },
                (error: Error) => {
                    console.log('error on saving address:', error)
                    this.setBackendErrorLoading(error.message)
                    this.setIsSubmittingAddressSave(false)
                }
            )
        )
    )

    readonly createUserAddress = this.effect<SaveUserAddressRequest>(
        pipe(
            concatMap((req) => {
                // 👇 Handle race condition with the proper choice of the flattening operator.
                this.setIsSubmittingAddressSave(true)
                return this.userAddressService
                    .createUserAddressDocument(
                        req.auth.currentUser?.userID!,
                        req.address
                    )
                    .pipe(
                        switchMap(() => {
                            req.auth.userPreferences
                            const prefs = {
                                ...req.auth.userPreferences,
                                has_address: true,
                            }
                            return this.auth.updateUserPreferences(prefs)
                        })
                    )
            }),
            tapResponse(
                (userPrefs) => {
                    // if we have document from the store means the user has address already
                    this.store.dispatch(
                        refreshUserPreferencesAction({
                            userPreferences: userPrefs,
                        })
                    )
                    this.setIsSubmittingAddressSave(false)
                },
                (error: Error) => {
                    console.log('error on saving address:', error)
                    this.setBackendErrorLoading(error.message)
                    this.setIsSubmittingAddressSave(false)
                }
            )
        )
    )

    readonly deleteAvatar = this.effect<UserPreferenceInterface>(
        pipe(
            // 👇 Handle race condition with the proper choice of the flattening operator.
            switchMap((userPrefs) => {
                this.setIsSubmittingDelete(true)
                return this.fileService
                    .deleteAvatarImg(userPrefs.avatar_id!)
                    .pipe(
                        switchMap(() => {
                            const prefs = {
                                ...userPrefs,
                                avatar_id: undefined,
                            }
                            return this.auth.updateUserPreferences(prefs).pipe(
                                tapResponse(
                                    (userPrefs) => {
                                        console.log(
                                            'successfully update prefs.',
                                            userPrefs
                                        )
                                        this.store.dispatch(
                                            refreshUserPreferencesAction({
                                                userPreferences: userPrefs,
                                            })
                                        )
                                    },
                                    (error: Error) => {
                                        console.log(
                                            'error updating user prefs:',
                                            error
                                        )
                                        this.setBackendErrorSave(error.message)
                                    }
                                )
                            )
                        }),
                        tapResponse(
                            (resposne) => {
                                console.log(
                                    'successfully deleteing avatar image.'
                                )
                                this.setAvatarInfo({
                                    avatarUrl: DefaultAvatarSVGPath,
                                    hasAvatar: false,
                                })
                                this.setIsSubmittingDelete(false)
                            },
                            (error: Error) => {
                                console.log(
                                    'error deleteing avatar image:',
                                    error
                                )
                                this.setBackendErrorUpload(error.message)
                                this.setIsSubmittingDelete(false)
                            }
                        )
                    )
            })
        )
    )

    readonly updateName = this.effect<UserNameRequest>(
        pipe(
            // 👇 Handle race condition with the proper choice of the flattening operator.
            switchMap((req) =>
                this.auth.updateUserName(req.firstName, req.lastName).pipe(
                    tapResponse(
                        (currentUser) => {
                            // send some messages to the frontend for successful save
                            console.log('successfully update name.')
                            this.store.dispatch(
                                refreshCurrentUserAction({ currentUser })
                            )
                        },
                        (error: Error) => {
                            console.log('error updating user name:', error)
                            this.setBackendErrorSave(error.message)
                        }
                    )
                )
            )
        )
    )
    readonly updatePhone = this.effect<UserPreferenceInterface>(
        pipe(
            // 👇 Handle race condition with the proper choice of the flattening operator.
            switchMap((prefs) =>
                this.auth.updateUserPreferences(prefs).pipe(
                    tapResponse(
                        (userPrefs) => {
                            console.log('successfully update prefs.', userPrefs)
                            this.store.dispatch(
                                refreshUserPreferencesAction({
                                    userPreferences: userPrefs,
                                })
                            )
                        },
                        (error: Error) => {
                            console.log(
                                'error updating user prefs - phone:',
                                error
                            )
                            this.setBackendErrorSave(error.message)
                        }
                    )
                )
            )
        )
    )

    readonly updateImage = this.effect<UploadImagePayload>(
        pipe(
            concatMap(({ userPrefs, userID, file }) => {
                console.log('value:', userPrefs.avatar_id)
                console.log('HAS avatar:', userPrefs)
                this.setIsSubmittingUpload(true)
                return this.fileService
                    .deleteAvatarImg(userPrefs.avatar_id!)
                    .pipe(
                        switchMap(() => {
                            const fileID =
                                this.fileService.generateAvatarImageID(userID)
                            return this.fileService
                                .addAvatarImg(file, fileID)
                                .pipe(
                                    switchMap(() => {
                                        // updating/adding avatar_id to prefs
                                        const prefs = {
                                            ...userPrefs,
                                            avatar_id: fileID,
                                        }
                                        return this.auth
                                            .updateUserPreferences(prefs)
                                            .pipe(
                                                tapResponse(
                                                    (userPrefs) => {
                                                        console.log(
                                                            'successfully update prefs.',
                                                            userPrefs
                                                        )
                                                        this.store.dispatch(
                                                            refreshUserPreferencesAction(
                                                                {
                                                                    userPreferences:
                                                                        userPrefs,
                                                                }
                                                            )
                                                        )
                                                    },
                                                    (error: Error) => {
                                                        console.log(
                                                            'error updating user prefs - phone:',
                                                            error
                                                        )
                                                        this.setBackendErrorSave(
                                                            error.message
                                                        )
                                                    }
                                                )
                                            )
                                    })
                                )
                        }),
                        tapResponse(
                            (prefs) => {
                                const avatarUrl =
                                    this.fileService.getAvatarPreviewUrl(
                                        prefs.avatar_id!
                                    )
                                this.setAvatarInfo({
                                    avatarUrl,
                                    hasAvatar: true,
                                })
                                this.setIsSubmittingUpload(false)
                                this.setShowUploadButton(false)
                            },
                            (error: Error) => {
                                console.log('upload avatar failed: ', error)
                                this.setIsSubmittingUpload(false)
                                this.setBackendErrorUpload(error.message)
                            }
                        )
                    )
            })
        )
    )

    readonly uploadNewImage = this.effect<UploadImagePayload>(
        pipe(
            concatMap(({ userPrefs, userID, file }) => {
                console.log('HAS No avatar:', userPrefs, userID)
                const fileID = this.fileService.generateAvatarImageID(userID)
                return this.fileService
                    .addAvatarImg(file, fileID)
                    .pipe(map(() => fileID))
                    .pipe(
                        switchMap((fileID) => {
                            // updating/adding avatar_id to prefs
                            const prefs = {
                                ...userPrefs,
                                avatar_id: fileID,
                            }
                            return this.auth.updateUserPreferences(prefs).pipe(
                                tapResponse(
                                    (userPrefs) => {
                                        console.log(
                                            'successfully update prefs.',
                                            userPrefs
                                        )
                                        this.store.dispatch(
                                            refreshUserPreferencesAction({
                                                userPreferences: userPrefs,
                                            })
                                        )
                                    },
                                    (error: Error) => {
                                        console.log(
                                            'error updating user prefs - phone:',
                                            error
                                        )
                                        this.setBackendErrorSave(error.message)
                                    }
                                )
                            )
                        }),
                        tapResponse(
                            (prefs) => {
                                const avatarUrl =
                                    this.fileService.getAvatarPreviewUrl(
                                        prefs.avatar_id!
                                    )
                                this.setAvatarInfo({
                                    avatarUrl,
                                    hasAvatar: true,
                                })
                                this.setIsSubmittingUpload(false)
                                this.setShowUploadButton(false)
                            },
                            (error: Error) => {
                                console.log('upload avatar failed: ', error)
                                this.setIsSubmittingUpload(false)
                                this.setBackendErrorUpload(error.message)
                            }
                        )
                    )
            })
        )
    )

    userAddressResponseToFormValues(doc: any): UserAddress {
        return {
            houseNo: doc['house_number'],
            street: doc['street'],
            city: doc['city'],
            state: doc['state'],
            country: doc['country'],
            postCode: doc['post_code'],
        }
    }
    errorNotFound(error: Error): boolean {
        return error.message.includes(
            'Document with the requested ID could not be found'
        )
    }
}
