import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, pipe, switchMap, tap } from 'rxjs'
import { AuthService } from '../../../shared/services/auth.service'
import { recoverSuccessAction } from '../actions/recover.actions'

export interface RecoverState {
    isSubmitting: boolean
    backendError: string | null
    invalidTokenError: string | null
}

export const initialState: RecoverState = {
    isSubmitting: false,
    backendError: null,
    invalidTokenError: null,
}

export interface ChangePasswordRequest {
    userId: string
    secret: string
    password: string
    confirmPassword: string
}

@Injectable()
export class RecoverStore extends ComponentStore<RecoverState> {
    constructor(private authService: AuthService, private store: Store) {
        super(initialState)
    }
    readonly isSubmitting$: Observable<boolean> = this.select(
        (state) => state.isSubmitting
    )

    readonly backendError$: Observable<string | null> = this.select(
        (state) => state.backendError
    )
    readonly invalidTokenError$: Observable<string | null> = this.select(
        (state) => state.invalidTokenError
    )
    private readonly setIsSubmitting = this.updater(
        (state, isSubmitting: boolean) => ({
            isSubmitting: isSubmitting,
            backendError: state.backendError,
            invalidTokenError: state.invalidTokenError,
        })
    )

    private readonly setBackendError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            invalidTokenError: null,
            backendError: error,
        })
    )
    private readonly setInvalidTokenError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            invalidTokenError: error,
            backendError: null,
        })
    )

    readonly updatePassword = this.effect<ChangePasswordRequest>(
        pipe(
            tap(() => this.setIsSubmitting(true)),
            // 👇 Handle race condition with the proper choice of the flattening operator.
            switchMap((request: ChangePasswordRequest) =>
                this.authService.updatePassword(request).pipe(
                    tapResponse(
                        (token) => {
                            this.store.dispatch(recoverSuccessAction())
                            console.log('token login state:', token)
                            this.setIsSubmitting(false)
                        },
                        (error: Error) => {
                            console.log('recover error:', error)

                            if (error.message.includes('Invalid token')) {
                                this.setInvalidTokenError(error.message)
                            } else {
                                this.setBackendError(error.message)
                            }
                        }
                    )
                )
            )
        )
    )
}
