import { Component } from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms'
import { provideComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import {
    RegisterRequestInterface,
    RegisterStore,
} from '../../store/states/register.state'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { TranslocoModule } from '@ngneat/transloco'
import { MatInputModule } from '@angular/material/input'

@Component({
    selector: 'mi-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [provideComponentStore(RegisterStore)],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatInputModule
    ],
})
export class RegisterComponent {
    signupForm: FormGroup
    hide = true
    confirmHide = true
    backendError$: Observable<string | null>
    isSubmitting$: Observable<boolean>
    userAlreadyExists$: Observable<string | null>
    constructor(
        private _fb: FormBuilder,
        private componentStore: RegisterStore
    ) {
        this.signupForm = this._fb.group(
            {
                email: ['', [Validators.required, Validators.email]],
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(40),
                    ],
                ],
                confirmPassword: ['', [Validators.required]],
            },
            { validators: this.passwordMatch }
        )
        this.backendError$ = this.componentStore.backendError$
        this.isSubmitting$ = this.componentStore.isSubmitting$
        this.userAlreadyExists$ = this.componentStore.userAlreadyExistsErr$
    }

    onSubmit(e: any): void {
        e.preventDefault()

        // check if the password did not match then make the confirm password field shows error
        if (this.signupForm.hasError('passwordMatch')) {
            this.signupForm.controls['confirmPassword'].setErrors({
                passwordMatch: true,
            })
        }
        if (this.signupForm.invalid) {
            return
        }

        const request: RegisterRequestInterface = {
            email: this.signupForm.value.email,
            password: this.signupForm.value.password,
        }
        this.componentStore.doRegister(request)
    }

    passwordMatch(control: AbstractControl): ValidationErrors | null {
        return control.get('password')?.value ===
            control.get('confirmPassword')?.value
            ? null
            : { passwordMatch: true }
    }

    get email() {
        return this.signupForm.get('email')
    }
    get password() {
        return this.signupForm.get('password')
    }
    get confirmPassword() {
        return this.signupForm.get('confirmPassword')
    }
}
