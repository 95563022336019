import { Component, OnInit } from '@angular/core'
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { ActivatedRoute, RouterModule } from '@angular/router'
import { provideComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import {
    LoginRequest,
    LoginStore,
    RETURN_URL_QUERY_PARAM,
} from '../../store/states/login.state'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [provideComponentStore(LoginStore)],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
    ],
})
export class LoginComponent implements OnInit {
    // TODO: add forgot password link and funcionality
    loginForm: FormGroup
    hide = true
    backendError$: Observable<string | null>
    invalidPasswordError$: Observable<string | null>
    isSubmitting$: Observable<boolean>
    returnUrl!: string
    constructor(
        private _fb: FormBuilder,
        private readonly componentStore: LoginStore,
        private route: ActivatedRoute
    ) {
        this.loginForm = this._fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(40),
                ],
            ],
        })
        this.backendError$ = this.componentStore.backendError$
        this.invalidPasswordError$ = this.componentStore.invalidPasswordError$
        this.isSubmitting$ = this.componentStore.isSubmitting$
    }
    ngOnInit() {
        this.route.queryParams.subscribe(
            (params) => (this.returnUrl = params[RETURN_URL_QUERY_PARAM] || '/')
        )
        this.componentStore.setState({
            isSubmitting: false,
            backendError: null,
            invalidPasswordError: null,
        })
    }

    onSubmit(e: any): void {
        e.preventDefault()

        if (this.loginForm.invalid) {
            return
        }

        console.log(this.loginForm.value)

        const request: LoginRequest = {
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
            returnUrl: this.returnUrl,
        }
        this.componentStore.doLogin(request)
    }

    get email() {
        return this.loginForm.get('email')
    }
    get password() {
        return this.loginForm.get('password')
    }
}
